import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n'
import { HelmetProvider } from 'react-helmet-async';

import { ToasterProvider } from "./providers/toast-provider";

import reportWebVitals from './reportWebVitals';

import './styles/globals.css';
import { lazy } from 'react';
import CookieConsentOverlay from './components/cookie-consent-overlay';
import { LoadingProvider } from './providers/loading-provider';
import MetaTags from './components/meta-tags';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage').then(({ NotFoundPage }) => ({ default: NotFoundPage })),);
const Home = lazy(() => import('./pages/Home').then(({ Home }) => ({ default: Home })));
const HomePage = lazy(() => import('./pages/home-page').then(({ HomePage }) => ({ default: HomePage })));
const Contacts = lazy(() => import('./pages/contacts').then(({ Contacts }) => ({ default: Contacts })));
const Services = lazy(() => import('./pages/services').then(({ Services }) => ({ default: Services })));
const Blog = lazy(() => import('./pages/blog').then(({ Blog }) => ({ default: Blog })));
const Portfolio = lazy(() => import('./pages/portfolio').then(({ Portfolio }) => ({ default: Portfolio })));
const About = lazy(() => import('./pages/about').then(({ About }) => ({ default: About })));
const TermsAndConditions = lazy(() => import('./pages/terms-and-conditions').then(({ TermsAndConditions }) => ({ default: TermsAndConditions })));

const DefaultService = lazy(() => import('./pages/default-service').then(({ DefaultService }) => ({ default: DefaultService })));
const SaveTheDate = lazy(() => import('./pages/services/save-the-date').then(({ SaveTheDate }) => ({ default: SaveTheDate })));
const WeddingHub = lazy(() => import('./pages/services/wedding-hub').then(({ WeddingHub }) => ({ default: WeddingHub })));
const SeatingPlanner = lazy(() => import('./pages/services/seating-planner').then(({ SeatingPlanner }) => ({ default: SeatingPlanner })));
const Baptism = lazy(() => import('./pages/services/baptism').then(({ Baptism }) => ({ default: Baptism })));
const Anniversary = lazy(() => import('./pages/services/anniversary').then(({ Anniversary }) => ({ default: Anniversary })));

const DemoSaveTheDate = lazy(() => import('./pages/demo/save-the-date').then(({ DemoSaveTheDate }) => ({ default: DemoSaveTheDate })));
const DemoWeddingHub = lazy(() => import('./pages/demo/wedding-hub').then(({ DemoWeddingHub }) => ({ default: DemoWeddingHub })));
const DemoSeatingPlanner = lazy(() => import('./pages/demo/seating-planner').then(({ DemoSeatingPlanner }) => ({ default: DemoSeatingPlanner })));

const Bar = lazy(() => import('./pages/home-services/bar').then(({ Bar }) => ({ default: Bar })));
const Inflatables = lazy(() => import('./pages/home-services/inflatables').then(({ Inflatables }) => ({ default: Inflatables })));
const DetailsWeddingHub = lazy(() => import('./pages/home-services/wedding-hub').then(({ WeddingHubDetails }) => ({ default: WeddingHubDetails })));
const SaveTheDateDetails = lazy(() => import('./pages/home-services/save-the-date').then(({ SaveTheDateDetails }) => ({ default: SaveTheDateDetails })));
const Design = lazy(() => import('./pages/home-services/design').then(({ Design }) => ({ default: Design })));
const SeatingPlannerDetails = lazy(() => import('./pages/home-services/seating-planner').then(({ SeatingPlannerDetails }) => ({ default: SeatingPlannerDetails })));
// const PricingPackages = lazy(() => import('./pages/services/pricing-packages').then(({ PricingPackages }) => ({ default: PricingPackages })));

// Initialize React Ga with your tracking ID
ReactGA.initialize('G-LC7KKF93J8');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <CookieConsentOverlay />
    <ToasterProvider />
    <LoadingProvider />
    <HelmetProvider>
      <MetaTags />
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/home" Component={Home} />
        <Route path="/contacts" Component={Contacts} />
        <Route path="/blog" Component={Blog} />
        <Route path="/about" Component={About} />
        <Route path="/portfolio" Component={Portfolio} />
        <Route path="/terms-and-conditions" Component={TermsAndConditions} />

        <Route path="/services" Component={Services} />
        <Route path="/services/design" Component={Design} />
        <Route path="/services/bar" Component={Bar} />
        <Route path="/services/inflatables" Component={Inflatables} />
        <Route path="/services/wedding-hub" Component={DetailsWeddingHub} />
        <Route path="/services/save-the-date" Component={SaveTheDateDetails} />
        <Route path="/services/seating-planner" Component={SeatingPlannerDetails} />
        {/* <Route path="/services/pricing-packages" Component={PricingPackages} /> */}

        <Route path="/demo/wedding-hub" Component={DemoWeddingHub} />
        <Route path="/demo/save-the-date" Component={DemoSaveTheDate} />
        <Route path="/demo/seating-planner" Component={DemoSeatingPlanner} />

        {/* Route for Wedding Detail page */}
        <Route path="/:event_id" Component={DefaultService} />
        <Route path="/:event_id/save-the-date/" Component={SaveTheDate} />
        <Route path="/:event_id/wedding-hub/" Component={WeddingHub} />
        <Route path="/:event_id/seating-planner/" Component={SeatingPlanner} />
        <Route path="/:event_id/baptism/" Component={Baptism} />
        <Route path="/:event_id/anniversary/" Component={Anniversary} />

        {/* Route for 404 Not Found page */}
        <Route path='*' Component={NotFoundPage} />
      </Routes>
    </HelmetProvider>
  </Router>
);

reportWebVitals();
